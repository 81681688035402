<template>
  <div>
    <div :id="chartsName" style="width:2rem;height:.45rem"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  props: {
    value: {
      default: 0,
    },
    chartsName: {
      type: String,
    },
    chartsStyle: {
      require: false,
      type: Object,
      default: () => {
        return {
          barColor: "#307B37",
          innerColor: "#0B0F12",
        };
      },
    },
    batteryTips: {
      type: String,
    },
  },
  data() {
    return {};
  },
  watch: {
    batteryTips() {
      this.initCharts();
    },
  },
  mounted() {
    this.initCharts();
  },
  methods: {
    initCharts() {
      let myBatteryCharts = echarts.init(
        document.getElementById(this.chartsName)
      );
      var category = [
        {
          value: this.value,
        },
      ]; // 类别
      var total = 100; // 数据总数
      let textTips = this.batteryTips;
      var datas = [];
      category.forEach((value) => {
        datas.push(value.value);
      });
      let option = {
        xAxis: {
          max: total,
          splitLine: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        grid: {
          left: this.setfontSize(0.1),
          top: this.setfontSize(0.35), // 设置条形图的边距
          right: this.setfontSize(1.5),
          bottom: this.setfontSize(0.35),
        },
        yAxis: [
          {
            type: "category",
            inverse: false,
            data: category,
            splitLine: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
          },
        ],
        series: [
          {
            // 内
            type: "bar",
            barWidth: this.setfontSize(0.175),
            silent: true,
            itemStyle: {
              normal: {
                color: this.chartsStyle.barColor,
              },
            },
            label: {
              normal: {
                show: false,
              },
            },
            data: category,
            z: 1,
            animationEasing: "elasticOut",
          },
          {
            // label
            type: "pictorialBar",
            symbolBoundingData: total,
            itemStyle: {
              normal: {
                color: "none",
              },
            },
            label: {
              normal: {
                formatter: (params) => {
									console.log(params)
                  // let text = '电量剩' + '{f| ' + (params.data * 100 / total) + '%}';
                  let text = textTips + params.data + "%";
                  return text;
                },
                fontSize: this.setfontSize(0.13),
                color: "#B2B2B2",
                position: "right",
                distance: 15, // 向右偏移位置
                show: true,
              },
            },
            data: datas,
            z: 0,
          },

          {
            name: "外框",
            type: "bar",
            barGap: "-140%", // 设置外框粗细
            data: [total],
            barWidth: this.setfontSize(0.28),
            itemStyle: {
              normal: {
                barBorderRadius: [this.setfontSize(0.02), this.setfontSize(0.02), this.setfontSize(0.02), this.setfontSize(0.02)],
                color: this.chartsStyle.innerColor, // 填充色
                barBorderColor: this.chartsStyle.barColor, // 边框色
                barBorderWidth: this.setfontSize(0.03), // 边框宽度
              },
            },
            z: 0,
          },
          {
            type: "scatter",
            symbol: "roundRect",
            symbolSize: [this.setfontSize(0.04), this.setfontSize(0.12)],
            symbolOffset: [this.setfontSize(0.02), this.setfontSize(-0.02)],
            symbolKeepAspect: true,
            itemStyle: {
              normal: {
                color: this.chartsStyle.barColor,
              },
            },
            data: [total],
          },
        ],
      };
      myBatteryCharts.setOption(option);
      window.addEventListener("resize", function () {
        myBatteryCharts.resize();
      });
    },
    setfontSize(res) {
      let docEl = document.documentElement,
        clientWidth =  window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      if (!clientWidth) return;
      let fontSize = 100 * (clientWidth / 1920);
      return res * fontSize;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>