<template>
  <div class="bgcard">
    <div class="workmsg">
      <div class="worktitle">
        <p>{{ $t("lang.basicInformation") }}</p>
        <div class="line"></div>
      </div>
      <div class="workcontent">
        <p>{{ $t("lang.equipmentName") }}：{{ equipment.name }}</p>
        <p>
          {{ $t("lang.model") }}：{{
            equipment.status
              ? equipment.deviceStatus.devType
              : equipment.modelName
          }}
        </p>
        <p>{{ $t("lang.hardwareVersion") }}：{{ equipment.hardVer?equipment.hardVer:'--' }}</p>
        <p>{{ $t("lang.softwareVersion") }}：{{ equipment.softVer?equipment.softVer:'--' }}</p>
        <p>SN：{{ equipment.mid |mids }}</p>
      </div>
    </div>
    <div class="workmsg">
      <div class="worktitle">
        <p>{{ $t("lang.workingMode") }}</p>
        <div class="line"></div>
      </div>
      <div class="workcontent">
        <p>
          {{ $t("lang.workingMode") }}：
          <span v-if="equip.workMode === 0">{{
            $t("lang.livebroadcast")
          }}</span>
          <span v-if="equip.workMode === 1">{{
            $t("lang.Losslessrecordingandbroadcasting")
          }}</span>
          <span v-if="equip.workMode === 2">{{ $t("lang.filetransfer") }}</span>
        </p>
        <p>{{ $t("lang.workingHours") }}：{{ equipment.uptime?equipment.uptime:'--' }}</p>
      </div>
    </div>
    <div class="workmsg">
      <div class="worktitle">
        <p>{{ $t("lang.Workingparameters") }}</p>
        <div class="line"></div>
      </div>
      <div class="workcontent workcont" v-if="equipment.status">
        <div class="contone">
          <p>
            {{ $t("lang.codingAlgorithm") }}：{{
              equip.encodeType ? "H.265" : "H.264"
            }}
          </p>
          <p>
            {{ $t("lang.transportProtocol") }}：{{
              equip.protocol === 1 ? "UDP" : "TCP"
            }}
          </p>
          <p>{{ $t("lang.codeRate") }}：{{ equipment.bitrateEncKb }}kbps</p>
        </div>
        <div class="conttwo">
          <p>{{ $t("lang.rateControl") }}：{{ equip.videoBitrate }}kb/s</p>
          <p>{{ $t("lang.delayed") }}：{{ equip.cache }}ms</p>
          <p>
            {{ $t("lang.transmissionRate") }}：{{
              ((recvrates * 8) / 1024).toFixed(2)
            }}Kbps
          </p>
        </div>
        <div class="contthree">
          <p>{{ $t("lang.inputSystem") }}：{{ equip_a.inputFmtStr }}</p>
          <p>
            {{ $t("lang.bitstreamMode") }}: {{ equip.videoCbr ? "CBR" : "VBR" }}
          </p>
        </div>
        <div class="contthree">
          <p>
            {{ $t("lang.FrameRateModel") }}:
            {{
              equip.encodeFps
                ? $t("lang.Halfframerate")
                : $t("lang.Fullframerate")
            }}
          </p>
          <p>{{ $t("lang.Inputinterface") }}: {{ equip_a.inputSrcStr }}</p>
        </div>
      </div>
			<div class="workcontent workcont" v-else>
			  <div class="contone">
			    <p>
			      {{ $t("lang.codingAlgorithm") }}：--
			    </p>
					<p>
						{{ $t("lang.transportProtocol") }}：{{
						  equip.protocol === 1 ? "UDP" : "TCP"
						}}
					</p>
			    <p>{{ $t("lang.codeRate") }}：--</p>
			  </div>
			  <div class="conttwo">
					<p>{{ $t("lang.rateControl") }}：{{ equip.videoBitrate }}kb/s</p>
					<p>{{ $t("lang.delayed") }}：{{ equip.cache }}ms</p>
			    <p>
			      {{ $t("lang.transmissionRate") }}：--
			    </p>
			  </div>
			  <div class="contthree">
			    <p>{{ $t("lang.inputSystem") }}：--</p>
			    <p>
			      {{ $t("lang.bitstreamMode") }}: --
			    </p>
			  </div>
			  <div class="contthree">
			    <p>
			      {{ $t("lang.FrameRateModel") }}:
			      {{
			        equip.encodeFps
			          ? $t("lang.Halfframerate")
			          : $t("lang.Fullframerate")
			      }}
			    </p>
			    <p>{{ $t("lang.Inputinterface") }}: --</p>
			  </div>
			</div>
			
    </div>

    <div class="workimg">
      <div class="worktitle">
        <p>{{ $t("lang.workingCondition") }}</p>
        <div class="line"></div>
      </div>
      <div class="workstate">
        <div class="imgleft">
          <div class="statetop">
            <div class="statemsg">
              <img style="margin-right: 0.11rem;height: 0.16rem;width: 0.16rem;" 
							 :src="equipment.status ? require('@/assets/imgs/zaixian.png') : require('@/assets/imgs/offLine.png')"/>
              <p>
                {{ equipment.status ? $t("lang.onLine") : $t("lang.outLine") }}
              </p>
            </div>
						<div v-if="equipment.status">
							<div class="statemsg" style="width: 1rem;margin-left: .1rem;" v-if="equipment.battery&&equipment.deviceStatus.batteryStatus!=2">
							  <span class="statemsg_a">
							    <div class="state" :style="{ width: battery }" :class="equipment.battery>0&&equipment.battery<25 ? 'states':''"></div>
									<img v-if="equipment.deviceStatus.batteryStatus==1" src="@/assets/imgs/devlis/chond.png" />
							  </span>
							  <div class="state_a"></div>
							  <p style="margin-left: 0.08rem">
							    {{ equipment.battery ? equipment.battery : 0 }}%
							  </p>
							</div>
							<div v-if="equipment.deviceStatus.batteryStatus==2" style="margin-right: .1rem;">
								<img style="display: block;" src="@/assets/imgs/devlis/notbit.png" />
							</div>
						</div>
						<div v-else>
							<div class="statemsg" style="width: 1rem;margin-left: .1rem;">
							  <span class="statemsg_a">
							    <div class="state" :style="{ width: battery }"></div>
							  </span>
							  <div class="state_a"></div>
							</div>
						</div>
            <div class="statemsg">
              <img style="width: 0.29rem;height: 0.22rem;margin-right: 0.08rem;" :src="require('@/assets/imgs/shipin.png')" />
              <p>{{ equipment.mediaNum }}{{ $t("lang.individual") }}</p>
            </div>
          </div>
          <div class="statebottom">
            <!-- <el-progress
              :percentage="((storag - storag_a) / storag) * 100"
              color="#307B37"
							v-if="!isNaN(parseInt(((storag - storag_a) / storag) * 100))"
              :show-text="false"
            ></el-progress> -->
						<div style="display: flex;align-items: center;" v-if="equipment.status">
							<span style="font-size: .16rem;">{{ $t("lang.diskSpace") }}：</span>
							<span>{{ storag_a }}GB{{ $t("lang.available") }}</span>
							<span style="margin: 0 2px;">/</span>
							<span>{{ $t("lang.common") }}{{ storag }}GB</span>
						</div>
						<div style="display: flex;align-items: center;" v-else>
							<span style="font-size: .16rem;">{{ $t("lang.diskSpace") }}：</span>
							<span>--</span>
						</div>
          </div>
        </div>
        <div class="imgright">
          <div class="bitmsg" v-for="(obj, i) in equipment.linkInfo" :key="i">
            <div class="bitrate-item" style="height: , 32rem">
              <div style="display: flex">
                <div :class="obj.enable ? 'orangeColor' : ''">
                  <p v-if="obj.type === 0">{{ obj.isp }}</p>
                  <p v-if="obj.type === 1">wifi</p>
                  <p v-if="obj.type === 2">4G</p>
                  <p v-if="obj.type === 3">5G</p>
                  <p v-if="obj.type === 4">2G</p>
                  <p v-if="obj.type === 5">3G</p>
                </div>
                <div class="xinhao1" style="margin-left: 0.08rem">
                  <div>
                    <div
                      class="xinhao"
                      v-if="
                        (obj.type === 2 && obj.signal > 0) ||
                        (obj.type === 3 && obj.signal > 0)
                      "
                    >
                      <div v-if="obj.signal > 0"></div>
                      <div v-if="obj.signal > 1"></div>
                      <div v-if="obj.signal > 2"></div>
                      <div v-if="obj.signal == 4 || obj.signal > 4"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <img v-if="obj.isp === 'MOBILE'" style="width: 0.23rem; height: 0.26rem"
                  :src="!obj.enable ? require('@/assets/imgs/yidong2.png') : require('@/assets/imgs/yidong.png')"/>
                <img v-if="obj.isp === 'UNICOM'" style="width: 0.25rem; height: 0.28rem"
                  :src="!obj.enable ? require('@/assets/imgs/liantong2.png') : require('@/assets/imgs/liantong.png')"/>
                <img v-if="obj.isp === 'TELECOM'" style="width: 0.24rem; height: 0.28rem"
                  :src="!obj.enable ? require('@/assets/imgs/dainxin2.png') : require('@/assets/imgs/dainxin.png')"/>
                <img v-if="obj.isp === 'wifi'" style="width: 0.25rem; height: 0.19rem"
                  :src="!obj.enable ? require('@/assets/imgs/wifi2.png') : require('@/assets/imgs/wifi.png')"/>
                <img v-if="obj.isp === 'eth'" style="width: 0.25rem; height: 0.21rem"
                  :src="!obj.enable ? require('@/assets/imgs/wangka2.png') : require('@/assets/imgs/wangka.png')"/>
              </div>
            </div>
            <div
              style="
                display: flex;
                justify-content: space-between;
                margin-top: 0.2rem;
                color: #195d94;
              "
              :class="obj.enable ? 'orangeColor' : ''"
            >
              <div>BitRate</div>
              <div>
                {{parseInt((Number(obj.recvRate[obj.recvRate.length - 1]) * 8) / 1024 )}}Kbps
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="workurl">
      <div class="worktitle">
        <p>{{ $t("lang.streamingAddress") }}</p>
        <div class="line"></div>
      </div>
      <div class="urlmsg">
        <div class="urlcontent" v-if="equip_a.pushstreamout">
          <div v-for="(obj, i) in equip_a.pushstreamout" :key="i" class="urlcontentss">
            <div>
							<span>{{ $t("lang.address") }}：</span>
							<span class="urlcontentssa">{{ obj.url }}</span>
						</div>
            <div>
							<span>{{ $t("lang.state") }}：</span>
							<span v-if="obj.stat == 0">{{ $t("lang.free") }}</span>
							<span v-if="obj.stat == 1">{{ $t("lang.free_a") }}</span>
							<span v-if="obj.stat == 2">{{ $t("lang.free_b") }}</span>
							<span v-if="obj.stat == 3">{{ $t("lang.free_c") }}</span>
							<span v-if="obj.stat == 4">{{ $t("lang.free_d") }}</span>
						</div>
          </div>
        </div>
        <div style="padding-left: 50px; color: #fff" v-else>
          <span>{{ $t("lang.tableNull") }}</span>
        </div>
      </div>
    </div>
    <!-- <div class="workurl">
      <div class="worktitle">
        <p>{{ $t("lang.Pullflowaddress") }}</p>
        <div class="line"></div>
      </div>
      <div class="urlmsg">
        <div class="urlcontent" v-if="checkList.length > 0">
          <el-checkbox-group v-model="checkListUrl" @change="changeClick">
            <el-checkbox :label="item" v-for="(item, index) in checkList" :key="index"></el-checkbox>
          </el-checkbox-group>
        </div>
        <div style="padding-left: 50px; color: #fff" v-else>
          <span>{{ $t("lang.tableNull") }}</span>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import * as equipments from "@/api/apis";
import batteryCharts from "./components/echarts/battery";
import bus from "@/utils/bus.js";
import * as userApi from "@/api/apis";
export default {
  components: {
    batteryCharts,
  },
  data() {
    return {
      mid: this.$route.query.mid,
      equipment: {},
			battery: "0%",
      chartsStyle: {
        barColor: "#B7BAC5",
        innerColor: "#222628",
      },
      batteryTips: this.$t("lang.electricitySurplus"),
      apiTimer: null,
      storag: 0, //储存空间总数
      storag_a: 0, //可用储存空间
      recvrates: 0,
      equip: {},
      equip_a: {},
      checkList: [],
      checkListUrl:[],
    };
  },
  created() {
    bus.$on("langchange", () => {
      this.batteryTips = this.$t("lang.electricitySurplus");
    });
    this.getEquipmentDetail();
  },
	filters:{
		mids(val){
			let sk=''
			if(val){
				sk=val.slice(16)
			}
			return sk
		}
	},
  mounted() {
    this.getEquipmentDetail();
    this.apiTimer = setInterval(() => {
      this.getEquipmentDetail();
    }, 3000);
    this.$once("hook:beforeDestroy", () => {
      clearInterval(this.apiTimer);
    });
  },
  methods: {
    // 选择
    changeClick(e){
      console.log(e)
      let email = JSON.parse(sessionStorage.getItem("user")).email;
      let deviceMid = this.mid;
		  let soe=[]
      e.forEach((item)=>{
       soe.push({ name: item, url: item }) 
      })
        let params = {
          deviceMid: deviceMid,
          email: email,
          name: "",
          nameUrls: soe,
        };
        userApi.addEqUrl(params).then((res) => {
          this.$message({
            message: res.msg,
            type: "success",
          });
        });
        this.$emit("refreshDeviceMsg");
      
    },
    async getEquipmentDetail() {
      let { data } = await equipments.getEqDetail({ mid: this.mid });
      if (data.linkInfo !== null && data.linkInfo.length !== 0) {
        data.linkInfo.forEach((obj) => {
          if (obj.recvRate && obj.recvRate != null) {
            obj.recvRate = obj.recvRate.split(",");
          }
        });
      }
      this.equipment = data;
      this.equip = data.deviceConfigDto;
      this.equip_a = data.deviceRealtimeInfoDto;
      
      if (data.steamAddr) {
        this.checkList = data.steamAddr.split(",");
      }
      
      if(data.deviceRealtimeInfoDto.streamout && data.deviceRealtimeInfoDto.streamout.length > 0){
        this.checkListUrl = [];
        data.deviceRealtimeInfoDto.streamout.forEach((item)=>{
          this.checkList.forEach((ims)=>{
            if(item.url === ims){
              console.log(item.url)
              this.checkListUrl.push(item.url)
            }
          })
        })
      }
      this.storag = (this.equipment.storageTotal / 1024 / 1024).toFixed(2);
      let strk =(this.equipment.storageTotal - this.equipment.storageUsed) / 1024 / 1024;
      this.storag_a = strk.toFixed(2);

      if (this.equip_a) {
        this.recvrates = this.equip_a.recvRate;
				if(this.recvrates){
					this.recvrates = this.recvrates.split(",");
					this.recvrates = Number(this.recvrates[this.recvrates.length - 1]);
				}
      }
			if ( this.equipment.battery != null || (this.equipment.storageTotal && this.equipment.storageUsed)) {
			  this.battery = this.equipment.battery + "%";
			  // this.percentage = this.equipment.storageUsed / this.equipment.storageTotal;
			  // this.percentage = this.percentage * 100;
			}
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-checkbox__label{
  color: #ffffff;
}
/deep/.el-checkbox__input.is-checked+.el-checkbox__label{
  color: #307b37;
}
/deep/.el-checkbox__input.is-checked .el-checkbox__inner, /deep/.el-checkbox__input.is-indeterminate .el-checkbox__inner{
      background-color: #307b37;
    border-color: #307b37;
}
.bgcard {
  width: 100%;
  border: none;
  border-radius: 2px;
  background: #222628;
  padding: 0.31rem 0.41rem 0 0.37rem;
  box-sizing: border-box;
}
.workmsg,
.workimg,
.workurl {
  padding-bottom: 0.45rem;
  .worktitle {
    display: flex;
		flex-flow: row;
		justify-content: flex-start;
    align-items: center;
    margin-bottom: 0.35rem;
    p {
      max-width: 1.75rem;
      font-size: 0.18rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: #dbdbdb;
    }
    .line {
			margin-left: .1rem;
      width: 85%;
      height: 1px;
      background: #797979;
      opacity: 0.28;
    }
  }
  // &:nth-of-type(2) {
  //   p {
  //     &:nth-of-type(2) {
  //       margin-left: 1.1rem;
  //     }
  //   }
  // }
}
.workcontent {
  display: flex;
  p {
    font-size: 0.16rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #b2b2b2;
    margin-left: 0.78rem;
  }
}
.workcont {
  width: 6.8rem;
  display: flex;
  justify-content: space-between;
  .contone,
  .conttwo,
  .contthree {
    P {
      width: 100%;
      white-space: nowrap;
    }
  }
  .contone {
    p {
      &:not(:first-of-type) {
        margin-top: 0.2rem;
      }
    }
  }
  .conttwo {
    // margin-left: 86px;
    p {
      &:not(:first-of-type) {
        margin-top: 0.2rem;
      }
    }
  }
  .contthree {
    // margin-left: 80px;
    p {
      &:not(:first-of-type) {
        margin-top: 0.2rem;
      }
    }
  }
}
.workimg {
  /* padding: 32px 0px 31px 0px; */
  .imgleft {
		width: 30%;
    padding-left: 0.78rem;
    display: flex;
    flex-direction: column;
    .statetop {
      display: flex;
			
      align-items: center;
      .statemsg {
				margin-right: .1rem;
        display: flex;
        align-items: center;
        p {
          font-size: 0.16rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: #b2b2b2;
        }
				.statemsg_a {
					position: relative;
				  display: inline-block;
				  width: 0.34rem;
				  height: 0.17rem;
				  border: 0.02rem solid #307B37;
				  border-radius: 0.03rem;
				  padding: 0.01rem;
				  .state {
				    background-color: #307B37;
				    height: 100%;
				  }
					.states{
						background-color: #d70000;
					}
					img{
						position: absolute;
						top: .03rem;
						right: 50%;
						display: block;
						width: .1rem;
						transform: translateX(50%);
					}
				}
				.state_a {
				  width: 0.02rem;
				  height: 0.1rem;
				  background-color: #307B37;
				}
        // img {
        //   margin-right: 0.11rem;
        //   &:first-of-type {
        //     width: 0.16rem;
        //     height: 0.16rem;
        //   }
        // }
        // &:nth-of-type(2) {
        //   img {
        //     width: 0.34rem;
        //     height: 0.19rem;
        //   }
        // }
        // &:last-of-type {
        //   img {
        //     width: 0.29rem;
        //     height: 0.22rem;
        //   }
        // }
      }
    }
  }
  .statebottom {
    width: 100%;
    margin-top: 0.53rem;
    display: flex;
    flex-direction: column;
		div{
			 color: #b2b2b2;
			display: flex;
			margin-top: 0.13rem;
			font-size: 0.14rem;
			font-family: Microsoft YaHei;
			font-weight: 400;
		}
  }
}
.workstate {
  display: flex;
	.imgright {
	  display: flex;
	  flex-wrap: wrap;
		.bitmsg {
			font-size: .13rem;
			display: flex;
			flex-flow: column;
			justify-content: space-between;
			width: 1.7rem;
			height: 0.7rem;
			background: #0f1316;
			border-radius: 0.1rem;
			padding:.12rem .08rem;
			margin-bottom: 0.1rem;
			margin-left: .15rem;
		  .bitrate-item{
		  	display: flex;
		  	flex-flow: row;
		  	justify-content: space-between;
		  	align-items: center;
		  }
		}
	}
}
.workurl {
  .urlmsg {
    display: flex;
    flex-wrap: wrap;
    margin-left: 0.34rem;
    .urlcontent {
      margin-left: 0.35rem;
      margin-bottom: 0.34rem;
      font-size: 0.14rem;
      color: #fff;
      .urlcontentss{
        margin-top: 0.1rem;
				display: flex;
				flex-flow: row;
				.urlcontentssa{
					color: #307b37; 
					font-size: 0.18rem;
					width: 4.5rem;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					margin-right: 0.2rem;
					display: inline-block;
				}
      }
      span {
        font-size: 0.16rem;
        font-family: Bahnschrift;
        font-weight: 400;
        color: #307b37;
        margin-right: 0.14rem;
      }
    }
  }
}
// .imgright {
//   width: 55%;
//   display: flex;
//   flex-wrap: wrap;
//   font-size: 0.14rem;
//   .bitmsg {
//     display: flex;
//     flex-direction: column;
//     min-width: calc(100% / 4.8);
//     height: 0.69rem;
//     background: #0f1316;
//     border-radius: 0.1rem;
//     padding: 0.08rem 0.06rem 0.12rem 0.08rem;
//     margin-bottom: 0.09rem;
//     &:not(:nth-of-type(4n)) {
//       margin-right: 0.17rem;
//     }
//     > p {
//       font-size: 0.13rem;
//       font-family: Microsoft YaHei;
//       font-weight: 400;
//       color: #195d94;
//       line-height: 0.3rem;
//       display: flex;
//       justify-content: space-between;
//     }
//     .bitrate {
//       display: flex;
//       justify-content: space-between;
//       align-items: flex-end;
//       margin-bottom: 0.1rem;
//       .bitrate-item {
//         display: flex;
//         align-items: center;
//         p {
//           font-size: 12px;
//           font-family: Microsoft YaHei;
//           font-weight: 400;
//           color: #195d94;
//           margin-right: 0.07rem;
//           position: relative;
//           top: 2px;
//         }
//         img {
//           &:first-of-type {
//             width: 0.18rem;
//             height: 0.16rem;
//           }
//           &:last-of-type {
//             width: 0.18rem;
//             height: 0.16rem;
//             position: relative;
//             top: 0.06rem;
//           }
//         }
//       }
//     }
//   }
// }
// .bitrate-item {
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   color: #307b37;
// }
.orangeColor {
  color: #307b37 !important;
}
.xinhao1 {
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
}
.xinhao {
  display: flex;
  flex-flow: row;
  align-items: flex-end;
  position: relative;
  top: -5px;
  div:nth-child(1) {
    width: 2px;
    height: 4px;
    background-color: #307b37;
    border-radius: 2px;
    margin-right: 2px;
  }
  div:nth-child(2) {
    width: 2px;
    height: 8px;
    background-color: #307b37;
    border-radius: 2px;
    margin-right: 2px;
  }
  div:nth-child(3) {
    width: 2px;
    height: 12px;
    background-color: #307b37;
    border-radius: 2px;
    margin-right: 2px;
  }
  div:nth-child(4) {
    width: 2px;
    height: 16px;
    background-color: #307b37;
    border-radius: 2px;
  }
}
</style>
